.container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  filter: blur(1px);
}
.grayscale {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: grid;
  align-content: center;
  justify-content: center;
}
@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}
.wait {
  background: var(--loader) no-repeat center center;
  background-size: 100%;
  height: 160px;
  width: 160px;
  left: 50%;
  margin-left: -80px;
  top: 50%;
  margin-top: -80px;
  position: absolute;
  animation: rotating 2s steps(28) infinite;
}
